import React from 'react';
import { useBackgroundColor } from './BackgroundColorContext';

function Learned() {
    const { isBackgroundBlack } = useBackgroundColor();
    return (
        <div id="learnedSection" className={isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"}>
            <h1 className="text-3xl ms:text-4xl text-center pt-12 pb-1">Voici la liste de ce que j'ai appris</h1>
            <div className="w-2/3 m-auto py-10 sm:pl-20">
                <h2 className="text-2xl font-bold mt-5">Confortable</h2>
                <lu>
                    <li>python</li>
                    <li>JavaScript</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JSX</li>
                    <li>React</li>
                    <li>NodeJS</li>
                    <li>ExpressJS(API)</li>
                    <li>Mongoose(MongoDB)</li>
                    <li>NextJS</li>
                    <li>Tailwind CSS</li>
                    <li>Linux(Debian)</li>
                    <li>Windows</li>
                    <li>SSH(Debian)</li>
                    <li>Uplink(Storj)</li>
                    <li>pfSense(routeur/pare-feu)</li>
                    <li>Cordova</li>
                    <li>Python API(Flask)</li>
                    <li>Python GUI(Tkinter)</li>
                    <li>Manipulation et analyse de données avec Python(Pandas)</li>
                    <li>Python web scraper(selenium)</li>
                    <li>Python web GUI(eel)</li>
                    <li>VirtualBox</li>
                </lu>
                <h2 className="text-2xl font-bold mt-5">Ce que j'ai déjà travailler avec</h2>
                <lu>
                    <li>MySQL*</li>
                    <li>WireShark(Debian)*</li>
                    <li>Metasploit*</li>
                    <li>Nmap*</li>
                    <li>Raspberry Pi B+</li>
                    <li>Raspberry Pi Pico (W)(microPython)</li>
                </lu>
                <p className="mt-2 font-bold">Les points avec un "*" veulent dire que je n'est pas la capacité a travailler avec immédiatement mais que je peux le réapprendre rapidement et facilement</p>
                <h1 className="mt-5 text-2xl font-bold">Ce que je suis en train d'apprendre</h1>
                <lu>
                    <li>Tensorflow</li>
                    <li>TypeScript</li>
                    <li>React Native</li>
                    <li>C (je m'amuse avec un ESP32)</li>
                    <li>Django</li>
                </lu>
            </div>
        </div>
    );
}

export default Learned;